import { createAction, props } from "@ngrx/store";

export const getPatientSearch = createAction('[Auth] get Patient Search',
    props<{ payload: any }>()
)
export const getPatientSearchSuccess = createAction('[Auth] get Patient Search Success',
    props<{ patientSearchData: any }>()
)
export const getUserProfile = createAction('[Auth] get User Profile',
    props<{ payload: any }>()
)
export const getUserProfileSuccess = createAction('[Auth] get User Profile Success',
    props<{ userProfileData: any }>()
)
export const getUserSign = createAction('[Auth] get User Sign',
    props<{ payload: any }>()
)
export const getUserSignSuccess = createAction('[Auth] get User Sign Success',
    props<{ userSignData: any }>()
)
export const addPatient = createAction('[Auth] add Patient',
    props<{ payload: any }>()
)
export const addPatientSuccess = createAction('[Auth] add Patient Success',
    props<{ addPatientData: any }>()
)
export const errorMessage = createAction('[Auth] error Message',
    props<{ errorData: any }>()
)
export const clearErrorMessage = createAction('[Auth] clear Error Message',
    props<{ payload: string }>()
)
export const clearErrorMessageSuccess = createAction('[Auth] clear Error Message Success')